/**
 * 顾客余量报表
 */
 import * as API from '@/api/index'

 export default {
    // 顾客余量
    accountStatistics: params => {
        return API.POST('api/customerStatement/accountStatistics', params)
     },
     // 购买门店
    allEntity: params => {
        return API.POST('api/entity/allEntity', params)
     },
    // 套餐卡列表
    packageCardAll: params => {
        return API.POST('api/packageCard/all', params)
     },
    // 余量导出
    accountStatisticsExcel: params => {
        return API.exportExcel('api/customerStatement/accountStatisticsExcel', params)
     },

 }