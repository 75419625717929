<template>
  <div class="content_body EmployeeSaleAndTreatCommissionStatistics" style="padding:15px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:0 0 15px 0">
      <el-form :inline="true" size="small" :model="EmployeeSaleAndTreatDetail" @submit.native.prevent>
        <el-form-item label="员工姓名">
          <el-input v-model="EmployeeSaleAndTreatDetail.Name" clearable
            @keyup.enter.native="handleEmployeeSaleAndTreatSearch" @clear="handleEmployeeSaleAndTreatSearch"
            placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item v-if="storeEntityList.length>1" label="所属门店">
          <el-select v-model="EmployeeSaleAndTreatDetail.EntityID" clearable filterable placeholder="请选择所属门店"
            :default-first-option="true" @change="handleEmployeeSaleAndTreatSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="EntityAllEntity.length>1 " label="业绩门店">
          <el-select v-model="EmployeeSaleAndTreatDetail.BuyEntityID" clearable filterable placeholder="请选择业绩门店"
            :default-first-option="true" @change="handleEmployeeSaleAndTreatSearch">
            <el-option v-for="item in EntityAllEntity" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-date-picker v-model="EmployeeSaleAndTreatDetail.QueryDate" unlink-panels type="daterange"
            range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleEmployeeSaleAndTreatSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="职务">
          <el-select v-model="EmployeeSaleAndTreatDetail.JobID" clearable filterable placeholder="请选择卡职务"
            :default-first-option="true" @change="handleEmployeeSaleAndTreatSearch">
            <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleEmployeeSaleAndTreatSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="EmployeeSaleAndTreatExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadEmployeeSaleAndTreatExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getsaleTreatDetailListSummaries" :data="result.list"
      v-loading="EmployeeSaleAndTreatLoading">
      <el-table-column prop="EmployeeName" label="员工姓名"></el-table-column>
      <el-table-column prop="JobName" label="职务"></el-table-column>
      <el-table-column prop="EntityName" label="所属门店（主）"></el-table-column>
      <el-table-column prop="BuyEntityName" label="业绩门店"></el-table-column>
      <el-table-column prop="CurrentSavingCardLargessAmount" align="right" label="本期赠送储值卡金额">
        <template slot-scope="scope">
          {{scope.row.CurrentSavingCardLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentProductLargessQuantity" align="right" label="本期赠送产品数量"></el-table-column>
      <el-table-column prop="CurrentProductLargessAmount" align="right" label="本期赠送产品市场价值">
        <template slot-scope="scope">
          {{scope.row.CurrentProductLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentCardLargessAmount" align="right" label="本期赠送卡项总价值">
        <template slot-scope="scope">
          <div v-if="scope.row.CurrentCardLargessAmount<0" class="color_red">
            {{scope.row.CurrentCardLargessAmount | NumFormat}}</div>
          <div v-else-if="scope.row.CurrentCardLargessAmount>0" class="color_green">
            +{{scope.row.CurrentCardLargessAmount | NumFormat}}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column prop="PayPerformance" align="right" label="成交实收总金额">
        <template slot-scope="scope">
          <div v-if="scope.row.PayPerformance<0" class="color_red">{{scope.row.PayPerformance | NumFormat}}</div>
          <div v-else-if="scope.row.PayPerformance>0" class="color_green">+{{scope.row.PayPerformance | NumFormat}}
          </div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardPerformance" align="right" label="储值卡抵扣金额">
        <template slot-scope="scope">
          <div v-if="scope.row.SavingCardPerformance<0" class="color_red">
            {{scope.row.SavingCardPerformance | NumFormat}}</div>
          <div v-else-if="scope.row.SavingCardPerformance>0" class="color_green">
            +{{scope.row.SavingCardPerformance | NumFormat}}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardLargessPerformance" align="right" label="赠卡抵扣金额">
        <template slot-scope="scope">
          <div v-if="scope.row.SavingCardLargessPerformance<0" class="color_red">
            {{scope.row.SavingCardLargessPerformance | NumFormat}}</div>
          <div v-else-if="scope.row.SavingCardLargessPerformance>0" class="color_green">
            +{{scope.row.SavingCardLargessPerformance | NumFormat}}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column prop="ConsumeProjectAmount" align="right" label="消耗项目实收业绩">
        <template slot-scope="scope">
          {{scope.row.ConsumeProjectAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ConsumeProjectQuantity" align="right" label="消耗项目数">
        <template slot-scope="scope">
          <div v-if="scope.row.ConsumeProjectQuantity<0" class="color_red">{{scope.row.ConsumeProjectQuantity }}</div>
          <div v-else-if="scope.row.ConsumeProjectQuantity>0" class="color_green">{{scope.row.ConsumeProjectQuantity }}
          </div>
          <div v-else>0</div>
        </template>
      </el-table-column>
      <el-table-column prop="FixCommission" label="固定手工费">
        <template slot-scope="scope">
          <div v-if="scope.row.FixCommission<0" class="color_red">{{scope.row.FixCommission | NumFormat}}</div>
          <div v-else-if="scope.row.FixCommission>0" class="color_green">+{{scope.row.FixCommission | NumFormat}}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="EmployeeSaleAndTreatPaginations.total > 0"
        @current-change="handleEmployeeSaleAndTreatPageChange" :current-page.sync="EmployeeSaleAndTreatPaginations.page"
        :page-size="EmployeeSaleAndTreatPaginations.page_size" :layout="EmployeeSaleAndTreatPaginations.layout"
        :total="EmployeeSaleAndTreatPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Employee/saleAndTreatCommissionStatistics";
import BuyEntityAPI from "@/api/Report/Customer/accountStatistics";
import EntityAPI from "@/api/Report/Common/entity";
import APIJob from "@/api/KHS/Entity/jobtype";
import permission from "@/components/js/permission.js";
import dateTime from '@/components/js/date'
export default {
  name: "EmployeeSaleAndTreatCommissionStatistics",
  components: {},
  directives: {},
  data() {
    return {
      loading: false,
      storeEntityList: [], //权限下所属门店、
      EntityAllEntity: [], // 业绩门店
      jobTypeData: [], //职务
      // 搜索条件
      EmployeeSaleAndTreatDetail: {
        Name: "",
        EntityID: null,
        BuyEntityID: null,
        QueryDate: [new Date(), new Date()],
        JobID: null,
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      EmployeeSaleAndTreatExport: false, //导出权限
      downloadLoading: false,
      // 是否加载图标
      EmployeeSaleAndTreatLoading: false,
      EmployeeSaleAndTreatPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  created() {
    this.EmployeeSaleAndTreatDetail.QueryDate = [
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
    ];
  },
  mounted() {
    const that = this;
    //   导出权限
    that.EmployeeSaleAndTreatExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Employee-SaleAndTreatCommissionStatistics-Export"
    );
    //获得当前用户下的权限门店
    that.getstoreEntityList();
    // 获取业绩门店
    that.getEntityAllEntity();
    // 员工职务
    that.getJobType();
    // 搜索
    that.handleEmployeeSaleAndTreatSearch();
  },

  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 获取业绩门店
    getEntityAllEntity() {
      var that = this;
      BuyEntityAPI.allEntity()
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityAllEntity = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 员工职务
    getJobType: function () {
      var that = this;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      APIJob.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 搜索
    handleEmployeeSaleAndTreatSearch() {
      var that = this;
      that.EmployeeSaleAndTreatPaginations.page = 1;
      that.getEmployeeSaleAndTreat();
    },
    // 获取员工销售消耗表
    getEmployeeSaleAndTreat() {
      const that = this;
      const param = that.EmployeeSaleAndTreatDetail;
      if (param.QueryDate != null) {
        that.EmployeeSaleAndTreatLoading = true;
        const params = {
          Name: param.Name,
          EntityID: param.EntityID,
          BuyEntityID: param.BuyEntityID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
          JobID: param.JobID,
          PageNum: that.EmployeeSaleAndTreatPaginations.page,
        };
        API.EmployeeSaleAndTreatList(params)
          .then((res) => {
            if (res.StateCode == 200) {
              // console.log('返回', res)
              that.result.totalForm =
                res.Data.employeeSaleAndTreatDetailSumStatementForm;
              that.result.list = res.Data.detail.List;
              that.EmployeeSaleAndTreatPaginations.total =
                res.Data.detail.Total;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.EmployeeSaleAndTreatLoading = false;
          });
      }
    },
    // 导出
    downloadEmployeeSaleAndTreatExcel() {
      var that = this;
      const param = that.EmployeeSaleAndTreatDetail;
      if (param.QueryDate != null) {
        const params = {
          EntityID: param.EntityID,
          BuyEntityID: param.BuyEntityID,
          Name: param.Name,
          JobID: param.JobID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
        };
        that.downloadLoading = true;
        API.EmployeeSaleAndTreatExcel(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "员工销售消耗业绩.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function () {
            that.downloadLoading = false;
          });
      }
    },
    // 合计
    getsaleTreatDetailListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "CurrentSavingCardLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentSavingCardLargessAmount
                )}
              </span>
            );
            break;
          case "CurrentProductLargessQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.CurrentProductLargessQuantity}
              </span>
            );
            break;
          case "CurrentProductLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentProductLargessAmount
                )}
              </span>
            );
            break;
          case "CurrentCardLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentCardLargessAmount
                )}
              </span>
            );
            break;
          case "PayPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.PayPerformance)}
              </span>
            );
            break;
          case "SavingCardPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.SavingCardPerformance)}
              </span>
            );
            break;
          case "SavingCardLargessPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.SavingCardLargessPerformance
                )}
              </span>
            );
            break;
          case "ConsumeProjectAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.ConsumeProjectAmount)}
              </span>
            );
            break;
          case "ConsumeProjectQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.ConsumeProjectQuantity}
              </span>
            );
            break;
          case "FixCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.FixCommission)}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleEmployeeSaleAndTreatPageChange() {
      this.getEmployeeSaleAndTreat();
    },
  },
};
</script>

<style lang="scss">
.EmployeeSaleAndTreatCommissionStatistics {
}
</style>