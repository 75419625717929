/**
 * 员工销售消耗业绩 
 */
 import * as API from '@/api/index'

 export default {
    // 员工销售消耗列表
    EmployeeSaleAndTreatList: params => {
        return API.POST('api/employeeSaleAndTreatDetailStatement/list', params)
     },
    // 员工销售消耗导出
    EmployeeSaleAndTreatExcel: params => {
        return API.exportExcel('api/employeeSaleAndTreatDetailStatement/excel', params)
     },

 }